import React from 'react';
import { Button, Container, CssBaseline, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';

const Home = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const navigateMicroirrigazione = () => {
        navigate('/microirrigazione');
    };

    const navigateScorrimento = () => {
        navigate('/scorrimento');
    };

    const navigateRotoloni = () => {
        navigate('/rotoloni');
    };

    const navigateLineari = () => {
        navigate('/lineari');
    };

    return (
        <Container
            sx={{
                backgroundImage: `url(/background.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '86vh',
                minWidth: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CssBaseline />
            <Paper
                elevation={3}
                style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the transparency here
                    padding: '20px',
                    width: isSmallScreen ? '100vw' : '50vw', // Set width to 100% on small screens,
                    paddingBottom: '40px',
                }}
            >
                <Grid container spacing={isSmallScreen ? 3 : 5} justifyContent="center">
                    <Grid item xs={12} sx={{ marginY: '10px' }}>
                        <Typography variant='h1' color='primary' sx={{ fontSize: '28px', fontWeight: 'bold' }}> Scegliere di cosa calcolare l'efficienza:</Typography>
                    </Grid>
                    {/* {!isSmallScreen && <Grid item xs={12} md={3}></Grid>} */}
                    <Grid item xs={12} md={5}>
                        <Button size="large" onClick={navigateMicroirrigazione} variant="contained" sx={{ width: '100%', backgroundColor: '#222a35' }}>
                            MICROIRRIGAZIONE
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Button size="large" onClick={navigateScorrimento} variant="contained" sx={{ width: '100%', backgroundColor: '#8a5826' }}>
                            SCORRIMENTO
                        </Button>
                    </Grid>
                    {/* {!isSmallScreen && <Grid item xs={12} md={3}></Grid>} */}
                    {/* {!isSmallScreen && <Grid item xs={12} md={3}></Grid>} */}
                    <Grid item xs={12} md={5}>
                        <Button size="large" onClick={navigateRotoloni} variant="contained" sx={{ width: '100%', backgroundColor: '#5a7246' }}>
                            ROTOLONI
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Button size="large" onClick={navigateLineari} variant="contained" sx={{ width: '100%', backgroundColor: '#35505d' }}>
                            LINEARI E PIVOT
                        </Button>
                    </Grid>
                    {/* {!isSmallScreen && <Grid item xs={12} md={3}></Grid>} */}
                </Grid>
            </Paper>
        </Container>
    );
};

export default Home;
