import React, { useEffect } from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Results = () => {
    const location = useLocation();
    const { answers, page, questions, efficiency } = location.state || {};
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);


    return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
            <Stack direction="column" sx={{ width: isSmallScreen ? "90vw" : "50vw" }}>
                <Typography variant="h1" color='primary' sx={{ fontSize: '28px', fontWeight: 'bold', marginBottom: "20px" }}>Calcolo efficienza: {page}</Typography>
                <Stack direction="row" spacing={2}>
                    <Typography variant='div' sx={{ width: "50%", fontSize: '20px', fontWeight: 'bold', marginBottom: "20px", textAlign: "right" }}>Efficienza:</Typography>
                    <Typography variant='div' color='primary' sx={{ width: "50%", fontSize: '20px', fontWeight: 'bold', marginBottom: "20px", textAlign: "left" }}>{efficiency} %</Typography>
                </Stack>
                <Typography variant="h1" color='primary' sx={{ fontSize: '20px', fontWeight: 'bold', marginTop: "50px", marginBottom: "20px" }}>Riepilogo:</Typography>
                <Stack direction="row" spacing={5} sx={{ marginBottom: "15px" }}>
                    <Typography variant='div' sx={{ width: "50%", fontSize: '18px', fontWeight: 'bold', textAlign: "right" }}>Domanda</Typography>
                    <Typography variant='div' sx={{ width: "50%", fontSize: '18px', fontWeight: 'bold', textAlign: "left", display: "flex", alignItems: "center" }}>Risposta</Typography>
                </Stack>
                {questions.map((question, i) => {
                    return (answers[i] !== undefined ? (
                        <Stack key={question.id} direction="row" spacing={5} sx={{ marginBottom: "15px" }}>
                            <Typography variant='div' color={question.id === 0 ? 'primary' : ''} sx={{ width: "50%", fontSize: '18px', textAlign: "right" }}>{i !== 0 ? question.text : 'Efficienza di base'}</Typography>
                            <Typography variant='div' sx={{ width: "50%", fontSize: '18px', textAlign: "left", display: "flex", alignItems: "center" }}>{answers[i]}</Typography>
                        </Stack>
                    ) : null)
                })
                }
            </Stack>
        </div>
    );
};

export default Results;
