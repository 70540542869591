import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';

export default function NavBar() {
  const isSmallScreen = window.innerWidth < 300;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "white" }}>
        <Toolbar disableGutters>
          <Link to="/" >
            <img src="/logo-cer.png" alt="Right" className={`logo-cer ${isSmallScreen ? 'small-screen' : ''}`} />
          </Link>

          <div style={{ flexGrow: 1 }} />

          <img src="/testata_servizio.jpg" alt="Right" className='servizio' />
        </Toolbar>
      </AppBar>
    </Box>
  );
}