import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './NavBar';
import Home from './pages/Home.jsx';
import Microirrigazione from './pages/Microirrigazione';
import Rotoloni from './pages/Rotoloni';
import Lineari from './pages/Lineari';
import Scorrimento from './pages/Scorrimento';
import Results from './pages/Results.jsx';

function App() {
  return (
    <Router basename='/decidirri'>
      <div className="App">
        <NavBar/>
        <Routes>
          <Route path="/risultati" element={<Results/>}/>
          <Route path="/microirrigazione" element={<Microirrigazione/>} />
          <Route path="/rotoloni" element={<Rotoloni/>} />
          <Route path="/lineari" element={<Lineari/>} />
          <Route path="/scorrimento" element={<Scorrimento/>} />
          <Route path="/" element={<Home/>} />
        </Routes>
      </div>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />)

export default App;
