import React, { useEffect, useState } from 'react';
import { Button, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormControl, RadioGroup, FormControlLabel, Radio, Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { baseUrl } from '../lib/const';

// const questions = [
//     { id: 0, text: "Selezionare efficienza di base:", options: ['Scorrimento e sommersione con alimentazione per gravità', 'Scorrimento e sommersione con alimentazione per sollevamento meccanico', 'Infiltrazione laterale a solchi'], type: 'select' },
//     { id: 1, text: "E' presente un sistema di misura e conteggio dei volumi prelevati:", options: ['Sì', 'No'], type: 'radio' },
//     { id: 2, text: 'Possibilità di irrigare a settori:', options: ['Sì', 'No'], type: 'radio' },
//     { id: 3, text: "Elementi che migiorano l'efficacia degli interventi irrigui:", options: ['No', 'Regolazione portata al campo', "Durata dell'adacquamento", 'Monitoraggio del fronte di avanzamento', 'Presenza sensori', 'Blocco automatico per pioggia', 'Due o più delle precedenti', "Altri elementi che migliorano l'efficacia irrigua"], type: 'select' },
//     { id: 4, text: 'Superficie (ha) dominata dal torrino:', type: 'text' },
//     { id: 5, text: 'App per la gestione della paratoia:', options: ['Sì', 'No'], type: 'radio' },
//     { id: 6, text: 'Stazione Agrometeorologica per la stima dei fabbisogni idrici:', options: ['Sì', 'No'], type: 'radio' },
//     { id: 7, text: 'Presenza Pluviometro aziendale:', options: ['Sì, con lettura manuale', 'Sì, con invio dati IoT', 'No'], type: 'radio' },
//     { id: 8, text: 'DSS IRRIGUO:', options: ['Sì', 'No'], type: 'radio' },
//     { id: 9, text: 'Sensoristica di umidità del suolo:', options: ['Sì', 'No'], type: 'radio' },
//     // Add more questions as needed
// ];

const Scorrimento = () => {
    const [answers, setAnswers] = useState({});
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl}domande?page=scorrimento`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setQuestions(data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        fetchData();
    }, [])

    const formStyle = {
        maxWidth: isSmallScreen ? '90vw' : '',
        width: isSmallScreen ? '' : '50vw',
    };

    const handleAnswerChange = (questionId, answer) => {
        setAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: answer }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${baseUrl}scorrimento/efficienza`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ answers })
        })

        let efficiency;
        let parsedAnswers;

        if (response.ok) {
            const data = await response.json()
            efficiency = data.totalEfficiency
            parsedAnswers = data.answers
            setAnswers(data.answers)
        }

        navigate('/risultati', { state: { answers: parsedAnswers, page: 'Scorrimento', questions, efficiency: Math.round(efficiency) } })
    };

    const isFormValid = () => {
        // Check if there is a valid answer for each question
        return questions.every((question) => {
            const answer = answers[question.id];
            switch (question.type) {
                case 'radio':
                    return answer !== undefined && answer !== '';
                case 'select':
                    return answer !== undefined && answer !== '';
                case 'text':
                    return answer !== undefined && answer.trim() !== '';
                default:
                    return false;
            }
        });
    };

    const renderQuestionType = (question) => {
        switch (question.type) {
            case 'radio':
                return (
                    <RadioGroup
                        sx={{ width: "50%", paddingLeft: "15px" }}
                        value={answers[question.id] || ''}
                        onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                    >
                        {question.options.map((option, index) => (
                            <FormControlLabel
                                key={index}
                                value={option}
                                control={<Radio />}
                                label={option}
                            />
                        ))}
                    </RadioGroup>
                );
            case 'select':
                return (
                    <div style={{ width: "50%", maxWidth: "50%", paddingLeft: "15px" }}>
                        <Select
                            sx={{ width: "100%" }}
                            value={answers[question.id] || ''}
                            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                        >
                            {question.options.map((option, index) => (
                                <MenuItem key={index} value={option} sx={{ whiteSpace: 'normal' }}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                );
            case 'text':
                return (
                    <TextField
                        sx={{ width: "50%", paddingLeft: "15px" }}
                        value={answers[question.id] || ''}
                        onChange={(e) => {
                            const numericValue = e.target.value.replace(/[^0-9,.]/g, '');
                            handleAnswerChange(question.id, numericValue)
                        }}
                        id="outlined-basic"
                        variant="outlined"
                        inputProps={{ pattern: "[0-9,.]*" }} />
                );
            // Add more cases as needed for other question types
            default:
                return null; // or handle unknown question types
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
            <form onSubmit={handleSubmit} style={formStyle}>
                <Stack direction="column">
                    <Typography variant="h1" color='primary' sx={{ fontSize: '28px', fontWeight: 'bold', marginBottom: "20px" }}>Inserire dati scorrimento</Typography>
                    {questions.map((question) => (
                        <FormControl key={question.id} component="fieldset" sx={{ marginY: "10px" }}>
                            <Stack direction="row">
                                <legend style={{ width: "50%", fontWeight: question.id === 0 ? 'bold' : '', textAlign: "right" }}>{question.text}</legend>
                                {renderQuestionType(question)}
                            </Stack>
                        </FormControl>
                    ))}
                    <div>
                        <Button disabled={!isFormValid()} sx={{ marginY: "40px", width: "200px", justifyContent: "center" }} type="submit" variant="contained" color="primary">
                            CALCOLA EFFICIENZA
                        </Button>
                    </div>
                </Stack>
            </form>
        </div>
    );
};

export default Scorrimento;
